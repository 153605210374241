/*!
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@use "../../styles/declaration/basic" as *;

.ts-error-boundary {
  width: 100%;
  height: 100%;
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;

  background: #BABABA;

  .ts-error-boundary__pic {
    transform: scale(1, 1.3);
    font: {
      size: 32px;
      weight: bold;
    }
    color: #616161;
    text-shadow: #e0e0e0 1px 1px 0;
  }

  .ts-error-boundary__title {
    font: {
      size: 24px;
      weight: bold;
    }
    white-space: pre-wrap;
    text-align: center;
    color: #616161;
    text-shadow: #e0e0e0 1px 1px 0;
  }

  .ts-error-boundary__error {
    .ts-error-boundary__error-title {
      font: {
        size: 20px;
        weight: 500;
      }
      text-decoration: underline;
    }

    .ts-error-boundary__error-message {
      white-space: pre-wrap;
      font: {
        size: 18px;
        weight: 500;
      }
    }
  }

  .ts-error-boundary__button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 50px;
    padding: 10px 20px;
    background: #ED2939;
    color: white;
    border-radius: 8px;

    &:hover {
      background: #D71223;
    }

    &:active {
      background: #A40C1A;
    }
  }

}