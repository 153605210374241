@use "../../index" as *;

.ts-prop-renderer {
  .ts-prop-renderer__label {
    font: {
      size: 0.8rem;
      weight: 600;
    }

    color: var(--prop-renderer__label-color);
  }
}

.ts-props-renderer__horizontal-chain {
  width: 100%;
  gap: 12px;
}