/*!
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// container
.ts-toaster {
  position: fixed;
  top: 0;
  left: auto;
  bottom: unset;
  display: inline-block;
  right: auto;
  margin: 16px;
  z-index: 400;
  background-color: #e8e8e8;

  .info {
    background-color: #49addb;
  }

  .success {
    background-color: #2ee06f;
  }

  .error {
    background-color: #ff4436;
  }
}