.editor-page {

  .editor-content {
    position: absolute;

    .items-list {
      width: 300px;

      .list-item {
        padding: 4px;
        padding-block: 8px;

        &:hover {
          background: cornsilk;
        }
      }

      .list-item__selected {
        background: aquamarine;

        &:hover {
          background: #b6d58c;
        }
      }
    }

    .ts-space {
      height: 100%;
      background: var(--separator-color);
    }

    .item-editor {
      div[data-lastpass-icon-root] {
        display: none;
      }

      flex-grow: 1;

      .editor-container {
        gap: 12px;
      }
    }
  }

  .add-item {
    width: 50px;
    height: 50px;
    font-size: 32px;
    position: absolute;
    top: calc(100% - 150px);
    left: calc(100% - 100px);
    background: #00A5F6;
    color: white;
    border-radius: 50%;
  }
}
