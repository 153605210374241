.ts-input {
  width: unset;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: gray;
  min-height: 30px;
  padding-inline: 4px;
  border-radius: unset;

  &:focus-visible {
    outline: unset;
    border-bottom: 1px solid #3ca0c6;
  }
}