@forward 'node_modules/@nu-art/thunderstorm/styles';

$margin: 15px;

.customer-order__shipping-sticker.dialog {
  width: 700px;
  max-height: 900px;
  overflow-y: auto;

  .packages-count {
    padding-top: $margin;
  }

  .ts-input {
    margin-left: 12px;
    width: 30px;
    border: 1px solid black;
    text-align: center;
  }

  .print-icon {
    width: 25px;
    height: 25px;
  }
}

.shipping-sticker__printable-area {
  height: 100%;

  .shipping-sticker__content_wrapper {
    padding: $margin;
    height: 100%;
    page-break-before: always;
    page-break-inside: avoid;
    page-break-after: always;


    .shipping-sticker__content {
      padding: $margin;
      width: 100%;
      height: 100%;
      border: 1px solid black;
      border-radius: 8px;
      gap: 12px;

      .shipping-sticker__title {
        width: 100%;
        font-size: 3rem;
        justify-content: space-between;

        & img {
          width: 60%;
        }

        & div {
          font-size: 4rem;
          margin-bottom: -10px;
        }
      }

      .shipping-sticker__pickup {
        width: 100%;
        font-size: 2.5rem;
        text-align: center;
      }

      .shipping-sticker__delivery-detail {
        width: 100%;
      }

      .barcode-value {
        width: calc(100%);
        z-index: 2;
        border-radius: 25px 25px 0px 0px;
        background: white;
        margin-top: -25px;
        text-align: center;
        font-size: 1.5rem;
      }

      .shipping-sticker__shipping-address {
        & div {
          font-size: 1.5rem;
        }

        .property-renderer {
          width: 100%;
          direction: rtl;
          @extend %ll_h_c;
          justify-content: space-between;

          .property-renderer__prop-key {
            margin-inline-end: 12px;
          }

          .property-renderer__prop-value {
            @extend %ll_h_c;

            & div {
              margin-inline-end: 12px;
            }
          }
        }
      }

      .shipping-sticker__bottom {
        direction: rtl;
        width: 100%;
        flex-grow: 1;
        justify-content: space-between;
      }

      .shipping-sticker__package-count {
        font-size: 4rem;
      }

      .shipping-sticker__separator {
        height: 2px;
        background: black;
        width: 100%;
        z-index: 3;
      }
    }
  }
}