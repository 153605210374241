.workspace {
  height: 100%;
  width: 100%;

  .workspace-navigation {
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    flex-grow: 1;

    .workspace-page {
      width: 100%;
      padding: 8px;
      flex-grow: 1;
      overflow-y: auto;
    }
  }
}