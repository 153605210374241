@forward 'node_modules/@nu-art/thunderstorm/styles';

$margin: 15px;

.customer-order__product-preview.dialog {
  .ts-dialog__header {
    width: 100%;
    padding: 8px;
    background: var(--header--background);
  }

  .ts-dialog__content {
    padding: 8px;

    .print-icon {
      margin-right: 12px;
    }
  }

}

.product-sticker__printable-area-wrapper {
  border: 1px solid black;
}

.product-sticker__printable-area {

  .content-small {
    font-size: 11px;
    margin-inline-end: 2px;
  }

  .content-larger {
    font-size: 12px;
  }

  .content-long {
    white-space: pre-wrap;
    font-size: 11px;
  }


  .printing-sticker {
    width: 145px;
    background-color: #FFFFFF;
    padding-block: 10px;
    padding-inline: 9px;
    direction: rtl;

    div {
      font-family: 'Arial', sans-serif;
    }
  }
}
