.ts-table {
  width: unset;
  border-collapse: collapse;
  border: none;
  table-layout: fixed;
  border-spacing: 0;

  //Table Head
  .ts-table__head .ts-table__tr {
    background: var(--table__header-background-color);
    height: 40px;
    border: none;

    .ts-table__th {
      font: {
        weight: 600;
      }
      color: var(--table__header-text-color);
      padding: 0 5px;
      border: 1px solid var(--table__border-color);
    }
  }

  //Table Body
  .ts-table__body .ts-table__tr {

    td.ts-table__td {
      //Dimensions
      height: 100%;
      padding-inline: 4px;
      border: 1px solid var(--table__border-color);

      //Text
      font: {
        size: 0.8rem;
        weight: 600;
      }

      //Colors
      color: var(--table__body-text-color);

    }

    &.selected {
      background: var(--table__body-row-background-selected);
    }
  }
}