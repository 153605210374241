/*
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@use "./Layouts_" as *;

.ll_v_l {
  @extend %ll_v_l
}

.ll_v_c {
  @extend %ll_v_c
}

.ll_v_r {
  @extend %ll_v_r
}

.ll_h_t {
  @extend %ll_h_t
}

.ll_h_c {
  @extend %ll_h_c
}

.ll_h_b {
  @extend %ll_h_b
}

.ts-grid {
  @extend %ts-grid
}