.editor {
  background-color: white;
  display: flex;
  flex-grow: 1;

  .add-new-button {
    margin: 10px;
  }

  .account{
    border: 1px dashed #8A8A8A;
margin: 5px;
  }

  .selected{
    background-color: #1DDBC6;
  }

  .selected-options{
    gap: 15px;
  }
}