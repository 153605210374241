.proxy-service-account-editor {
  padding: 20px;

  .form-wrapper {
    gap: 15px;

    .title {
      color: #333333;
      font-size: 18px;
    }

    .ts-input {
      border: 1px solid #e3e9f2;
      border-radius: 4px;
      background: none;
      width: 65%;
      max-width: unset;
    }

    .ts-prop-renderer {
      gap: 10px;

      .ts-button {
        margin-inline: unset;
      }

      .ts-prop-renderer__label {
        color: #333333;
        font-size: 14px;
      }
    }

    .ts-dropdown {
      width: 65%;

      .ts-dropdown__header {
        border: 1px solid #e3e9f2;
        border-radius: 4px;
        background: none;

        .ts-dropdown__placeholder {
          font-style: normal;
          color: #333333;
        }
      }

      .ts-dropdown__items-container {
        border: none;
      }


      .ts-tree {
        .ts-tree__children-container {
          .ts-tree__node {
            background: none;
            padding: 4px;
            cursor: pointer;
            border: 1px solid #e3e9f2;

            &:hover {
              background: rgb(210, 219, 234);
            }
          }
        }
      }
    }

    @mixin tag($lightColor,$darkColor) {
      background: $lightColor;
      border: 1px solid $darkColor;
      border-radius: 5px;
      min-width: 70px;
      height: 28px;
      padding-block: 4px;
      padding-inline: 8px;
      flex-shrink: 0;
    }
  }

  .buttons-container {
    position: static;
    justify-content: flex-end;
    gap: 5px;
    width: 100%;
  }

  .ts-button {
    height: 40px;
    border: 1px solid #e3e9f2;
    color: #333333;
    font-size: 14px;
    background: white;
    border-radius: 5px;
    padding: 2px 20px 2px 20px;
    font-weight: 400;
  }
}