@use "@res/styles" as styles;
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import "@res/styles/vars";
@import '@res/styles/defaults';
@import "@res/styles/components";

* {
  -webkit-user-drag: none;
  box-sizing: border-box;
  font-family: var(--font-family-main);
  font-size: var(--font-size);
  white-space: nowrap;
}

body {
  margin: 0;
  overflow: hidden;
  height: 100vh;

  #root {
    height: 100%;
  }

  #app {
    height: 100%;
  }
}

img {
  display: inline-block;
}

.children_fill {
  place-items: stretch;
}

.children_space {
  justify-content: space-between;
}

input {
  font-size: var(--font-size);
}

.ts-popup-menu {
  .ts-popup-menu__menu {
    width: unset;
    min-width: 150px;

    .ts-tree {
      .ts-tree__node {
        &:hover {
          background: var(--color__popup-menu__item-hover);
        }
      }
    }
  }
}

.flex__shrink {
  flex-shrink: 1;
}

.width-25 {
  width: 25%
}

.width-30 {
  width: 30%
}

.width-50 {
  width: 50%
}

.gap__s {
  row-gap: 4px;
  column-gap: 4px
}

.gap__n {
  row-gap: 8px;
  column-gap: 8px
}

.gap__l {
  row-gap: 12px;
  column-gap: 12px
}

.v-gap__s {
  row-gap: 4px;
}

.v-gap__n {
  row-gap: 8px;
}

.v-gap__l {
  row-gap: 12px;
}

.h-gap__s {
  column-gap: 4px;
}

.h-gap__n {
  column-gap: 8px;
}

.h-gap__l {
  column-gap: 12px;
}