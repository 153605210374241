/*!
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.ts-popup.ts-overlay {
  z-index: 300;
  background: #00000020;

  .ts-overlay__child {
    position: absolute;
  }

  .ts-popup__content {
    z-index: 301;
    cursor: default;
    position: fixed;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 60vh;
    border-radius: 2px;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.3);
    border: solid 1px transparent;
    background-color: white;

    .ts-popup__content__menu {
      .ts-tree__node {
        padding-inline: 10px;
        white-space: nowrap;

        &:hover {
          background: cornsilk;
        }
      }
    }
  }
}