@use '../../../res/styles/_DO-NOT-IMPORT/palette' as p;
@use 'sass:math';

@mixin createColorClasses($name,$limit) {
  $pah: math.div($limit, 2);
  @for $_i from 0 to $limit {
    $invert: (math.floor(math.div($limit - $_i - 1, $pah)) * ($limit - 1)) + 1 ;
    //$invert: (math.floor(math.div($limit+3, 2))) % $limit + 1;
    $i: $_i + 1;
    .#{$name}-#{$i} {
      @if $name == 'black' {
        background: p.black($i);
        color: p.black($invert);
      }
      @if $name == 'amber' {
        background: p.amber($i);
        color: p.amber($invert);
      }
      @if $name == 'gold' {
        background: p.gold($i);
        color: p.gold($invert);
      }
      @if $name == 'blue' {
        background: p.blue($i);
        color: p.blue($invert);
      }
      @if $name == 'green' {
        background: p.green($i);
        color: p.green($invert);
      }
      width: 50px;
      height: 50px;
    }
  }
}

.palette-container {
  width: 90vw;
  padding: 20px;
  overflow-x: scroll;

  .color-row {
    width: 100%;
    display: flex;

    @include createColorClasses('black', p.$colorChunks);
    @include createColorClasses('amber', p.$colorChunks);
    @include createColorClasses('gold', p.$colorChunks);
    @include createColorClasses('blue', p.$colorChunks);
    @include createColorClasses('green', p.$colorChunks);
  }

  .color-label {
    width: 60px;
    text-align: right;
    margin-inline-end: 12px;
  }
}