@use 'node_modules/@nu-art/thunderstorm/styles' as *;

$bar-width: 80px;
$width-duration: 100ms;
$short-duration: 40ms;
.manager__side-bar {
  //Dimensions
  width: $bar-width;
  min-width: $bar-width;
  height: 100%;
  flex-shrink: 0;

  //Colors
  background-color: var(--color__background__side-bar);
  color: black;

  //Display
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;

  transition: width $width-duration ease-out;
  overflow: hidden;

  &.open {
    width: 240px;
    transition: width $width-duration ease-in;
  }

  .selected {
    background: var(--color__background__side-bar__selected);
  }

  //Children
  .manager__side-bar-item {
    //Dimensions
    width: 100%;
    height: $bar-width;

    //Display
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    //Colors
    transition: background $short-duration;

    //Position
    position: relative;

    .manager__side-bar-item__label {
      opacity: 0;
      overflow: hidden;
      white-space: nowrap;
      transition: opacity 500ms;
      color: var(--color__icons__side-bar);
      pointer-events: all;
      font-weight: bold;

      &.open {
        opacity: 1;
        transition: opacity $short-duration;
        pointer-events: none;
      }
    }

    .manager__side-bar__icon-wrapper {
      width: $bar-width;
      min-width: $bar-width;
    }

    & .icon--default {
      @include icon-size(24px, 24px);
      @include icon-style-v4(var(--color__icons__side-bar));
      transition: all $short-duration ease-in-out;
    }

    //States
    &:hover {
      background: var(--color__background__side-bar__hover);

      & .manager__side-bar-item__label {
        color: var(--color__icons__side-bar__hover);
      }

      & .icon--default {
        background: var(--color__icons__side-bar__hover);
      }
    }

    &:active {
      background: var(--color__background__side-bar__active);

      & .icon--default {
        background: var(--color__icons__side-bar__hover);
      }

      & > .manager__side-bar-item__label {
        color: var(--color__icons__side-bar__hover);
      }
    }

    &.selected {
      background: var(--color__background__side-bar__selected);

      & .icon--default {
        background: var(--color__icons__side-bar__hover);
        @include icon-size(48px, 48px);
      }

      .manager__side-bar-item__label {
        color: var(--color__icons__side-bar__hover);
      }
    }
  }
}
