@use '@nu-art/ts-styles' as styles;

.sync-env-page {
  width: 100%;
  height: 100%;

  .sync-env-page__main {
    padding: 0 16px;
    gap: 16px;
    align-items: flex-end;

    .ts-prop-renderer {
      width: unset;

      .ts-dropdown {
        width: 200px;
      }

      .ts-input {
        width: 300px;
        background: white;
        border: 2px solid black;
        border-radius: 10px;
        font: {
          size: 14px;
        }
      }

      .ts-prop-renderer__error {
        display: none;
      }
    }

    .ts-busy-button {
      @include styles.mouse-interactive-background(styles.dark-blue(2), styles.dark-blue(1));
      padding: 8px 16px;

      &.ts-busy-button__disabled {
        background: styles.dark-blue(2);
        opacity: 0.5;
      }
    }
  }

  .sync-env_modules-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

  }
}