.page__shipment-list {

  .ts-table {
    width: 100%;
  }

}


.page__shipment-v2 {
  .ts-space {
    height: 100%;
    margin-inline: 8px;
    background: grey;
  }

  .ts-tabs {
    flex-grow: 1;
    height: 100%;
  }

  .shipment-overview {
    min-width: 250px;
  }
}