/*!
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.ts-checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  .ts-checkbox__content {
    margin-inline-start: 8px;
  }

  .ts-checkbox__button {
    border: 1px solid #68678d50;
    box-shadow: 0 0 1px 0 #867979;
    padding: 1px;

    &.ts-checkbox__button__rounded {
      border-radius: 50%;
    }

    & .ts-checkbox__button__disabled {
      // ...
    }

    .ts-checkbox__button__inner {
      width: 18px;
      height: 18px;

      &.ts-checkbox__button__rounded {
        border-radius: 50%;
      }

      &.ts-checkbox__button__checked {
        background: #4fa7ff;
        border: .5px white solid;
      }

      &.ts-checkbox__button__unchecked {
      }
    }
  }
}
