.menu-item__message-template {
  justify-content: space-between;

  .info-circle {
    margin-left: 8px;
  }

}

.message-template__tooltip-preview {
  direction: rtl;
  width: 400px;
  height: 200px;
}
