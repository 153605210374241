.ts-button-loader {
  display: inline-block;
  position: relative;
  width: 52px;
  height: 14px;
}

.ts-button-loader div {
  position: absolute;
  top: 2px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.ts-button-loader div:nth-child(1) {
  left: -2px;
  animation: lds-ellipsis1 0.6s infinite;
}

.ts-button-loader div:nth-child(2) {
  left: -2px;
  animation: lds-ellipsis2 0.6s infinite;
}

.ts-button-loader div:nth-child(3) {
  left: 22px;
  animation: lds-ellipsis2 0.6s infinite;
}

.ts-button-loader div:nth-child(4) {
  left: 46px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
