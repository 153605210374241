/*!
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.ts-drag-and-drop {
  width: 100%;
  height: 100%;
  border-radius: 20px !important;

  > * {
    flex-grow: 1;
    height: 100%
  }

  .ts-drag-and-drop__content {
    width: 100%;
    height: 100%;
  }

  .ts-drag-and-drop__input {
    width: 100%;
    height: 100%;
  }

  .ts-drag-and-drop__idle {
    border: 2px dashed #ccc;
  }

  .ts-drag-and-drop__dragging {
    border: 2px dashed #2556ff;
  }

  .ts-drag-and-drop__positive {
    border: 4px dashed #00860c;
  }

  .ts-drag-and-drop__negative {
    border: 4px dashed red;
  }

  .ts-drag-and-drop__partial {
    border: 2px dashed #ea9b34;
  }
}