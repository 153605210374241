@use '@res/styles/index.scss' as *;

.customer-order__section {
  padding: 12px;
  background: var(--color__background__customer-order-section);
  width: 100%;
  max-width: 100%;
  border-radius: 5px;

  * {
    white-space: pre-wrap;
  }

  .customer-order__title {
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 8px;
    justify-content: space-between;

    > div {
      font-size: 18px;
      font-weight: 600;
    }
  }

  .customer-order__bold {
    font-size: 18px;
    font-weight: 600;
  }
}

.customer-order__items {
  .ts-table {
    height: 100%;
    width: 100%;

    .image {
      box-sizing: content-box;
    }
  }
}

.customer-order {

  .delivery-details {
    gap: 4px;

    .delivery-details-item {
      padding: 8px;
      border-radius: 6px;
    }
  }
}

.show-shipping-sticker {
  margin-left: 12px;
}

.show-shipping-sticker, .disable {
}

.customer-order__shipping-method {
  .ts-dropdown {
    flex-shrink: 1;
  }
}

.customer-order__packaging-status {
  .delivery-package {
    width: 100%;

    span {
      margin-inline-start: 12px;
      margin-inline-end: 8px;
    }

    .ts-dropdown {
      height: 30px;
      width: unset;
      flex-grow: 1;
    }
  }
}