@use '@nu-art/ts-styles' as styles;

.sync-env-page {
  width: 100%;
  height: 100%;

  .sync-env-page__main {
    padding: 0 16px;
    gap: 16px;
    align-items: flex-end;

    .ts-loader {
      height: 40px;
      width: 40px;

    }

    .hidden {
      visibility: hidden;
    }

    .ts-prop-renderer {
      width: unset;

      .ts-dropdown {
        width: 200px;
      }

      .ts-input {
        width: 300px;
        background: white;
        border: 2px solid black;
        border-radius: 10px;
        font: {
          size: 14px;
        }
      }

      .ts-prop-renderer__error {
        display: none;
      }
    }

    .ts-busy-button {
      @include styles.mouse-interactive-background(styles.dark-blue(2), styles.dark-blue(1));
      padding: 8px 16px;

      &.ts-busy-button__disabled {
        background: styles.dark-blue(2);
        opacity: 0.5;
      }
    }
  }

  .sync-env_modules-list-v2 {
    flex-grow: 1;
    row-gap: 5px;
    column-gap: 25px;
    margin-top: 10px;
    padding: 0 10px 10px 10px;
    flex-wrap: wrap;
    height: 0;

    .utils {
      width: 500px;
      justify-content: space-between;
      align-items: flex-end;

      .ts-checkbox {
        gap: 5px;

        .ts-checkbox__content {
          margin-inline-start: 0;
          font-size: 1rem;
        }
      }
    }

    .ts-prop-renderer {
      justify-content: space-between;
      width: 500px;

      .ts-prop-renderer__label {
        color: #121212;
        font-weight: normal;

        .header {
          gap: 5px;
        }
      }

      .collection-row {
        .backup-info {
          gap: 3px;

          div {
            min-width: 50px;
            text-align: right;

            &.left-row {
              text-align: center;
            }
          }
        }
      }
    }
  }
}
