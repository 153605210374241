/*!
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@use "../Layouts/Layouts_" as *;
@use "../../styles/declaration/basic" as *;

.ts-dialog__overlay {
  .ts-overlay {
    @extend %match_parent;
    @extend %ll_h_c;
    z-index: 200;
    background: #00000020;
    justify-content: center;
    align-items: center;
  }

  .ts-overlay__child {
    z-index: 201;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}