@use '../../_DO-NOT-IMPORT/palette' as palette;
@use 'node_modules/@nu-art/thunderstorm/styles' as *;


.ts-dialog {
  $borderRadius: 6px;
  border-radius: $borderRadius;

  .ts-dialog__header {
    background: var(--header--background);
  }

  .ts-dialog__main {
    padding: 8px;

  }
}
