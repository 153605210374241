$barWidth: 400px;
$color: rgb(29, 176, 255);


.ts-progress-bar {

  .ts-progress-bar__linear-bar {
    width: $barWidth;
    height: 24px;
    border: 2px solid $color;
    border-radius: 12px;
    position: relative;
    background: white;
    overflow: hidden;

    .ts-progress-bar__linear-bar__text {
      width: $barWidth;
      height: 100%;
      color: $color;
      font: {
        size: 16px;
        weight: bold;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      inset: 0;
    }

    .ts-progress-bar__linear-bar__bar {
      height: 100%;
      background: $color;
      transition: width 200ms ease-out;
      overflow: hidden;
      position: absolute;
      inset: 0;

      .ts-progress-bar__linear-bar__text {
        color: white;
      }
    }
  }

  .ts-progress-bar__radial-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 200px;
    position: relative;

    .ts-progress-bar__radial-bar__text {
      font: {
        size: 24px;
        weight: bold;
      }
      color: $color;
    }

    .ts-progress-bar__radial-bar__bar {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;

      circle {
        fill: none;
        stroke-width: 6;
        stroke: $color;
        transition: stroke-dashoffset 200ms ease-out;
      }
    }
  }
}