/*!
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@use 'sass:math';
@use 'sass:list';

@mixin icon-size($width, $height: $width) {
  width: $width;
  height: $height;
  min-width: $width;
  min-height: $height;
}

@mixin icon-style-v4($color, $hoverColor:$color, $mouseDownColor:$hoverColor, $disabledColor:$color) {
  display: inline-block;
  background: $color;

  &:hover {
    background: $hoverColor;
  }

  &:active {
    background: $mouseDownColor;
  }
}

@mixin icon-url($icon) {
  -webkit-mask-image: url($icon);
  mask-image: url($icon);
}

@mixin spaces($depths, $depth-padding:15,$class-prefix:'depth') {
  $depth-unit: 'px';
  @for $i from 1 through $depths {
    $val: $depth-padding * $i;
    &.#{$class-prefix}-#{$i} {
      padding-left: #{$val}#{$depth-unit};
    }
  }
}