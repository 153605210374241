/*!
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

%ll_v_l {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}

%ll_v_c {
  display: flex;
  flex-flow: column;
  align-items: center;
}

%ll_v_r {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
}

%ll_h_t {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
}

%ll_h_c {
  display: flex;
  flex-flow: row;
  align-items: center;
}

%ll_h_b {
  display: flex;
  flex-flow: row;
  align-items: flex-end;
}

%ts-grid {
  display: grid;
}
