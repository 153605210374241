.product-template-v2 {
  height: 100%;

  .product-template__overview {
    .product-template__general-props {
      gap: 12px;
      width: 400px;
    }
  }

  .ts-space {
    height: 100%;
    margin-inline: 8px;
    background: grey;
  }

  .ts-tabs {
    flex-grow: 1;
  }
}

.ts-table--editing-row {
  border: 2px solid black;
}