@use '../../index' as p;

@mixin light {
  //General
  --dialog__content-background: p.$white;

  //Header
  --dialog__header-background: #{p.blue(2)};
  --dialog__header-text-color: p.$white;

  //Fields
}
