.sm-login {
  height: auto;
  border: 1px solid #aaaaaa;
  border-radius: 5px;
  padding-inline: 16px;
  padding-top: 32px;
  padding-bottom: 16px;

  .sm-login__title {
    font-size: 28px;
    margin-bottom: 16px;
  }

  .ts-account__login {
    height: 100px;
    justify-content: space-evenly;
  }

  .sm-login__swap {
    color: blue;
    place-self: end;
    margin-top: 24px;
    text-decoration: underline;
  }
}