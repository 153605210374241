/*!
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.ts-dialog {
  //Dimensions
  width: 600px;
  min-height: 200px;
  padding: 0;
  margin: 0;

  //Positioning
  justify-content: space-between;

  //Design
  background: white;
  overflow: hidden;
  border-radius: 8px;
  outline: none !important;

  .ts-dialog__header {
    //Dimensions
    width: 100%;
    height: 50px;
    padding: 0 16px;

    //Positioning
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    //Color
    background: #808080;

    //Font
    font: {
      size: 20px;
      weight: bold;
    }

    .header__title {
      font: {
        size: inherit;
        weight: inherit;
      }
      color: white;
    }
  }

  .ts-dialog__main {
    flex-grow: 1;
    width: 100%;
  }

  .ts-dialog__buttons {
    //Dimensions
    width: 100%;
    height: 50px;
    flex-shrink: 0;
    padding: 0 16px;
    margin: 0;

    //Positioning
    display: grid;
    column-gap: 8px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "left center right";

    .ts-dialog__buttons__left,
    .ts-dialog__buttons__center,
    .ts-dialog__buttons__right {
      height: 100%;
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .ts-dialog__buttons__left {
      justify-content: flex-start;
      grid-area: left
    }

    .ts-dialog__buttons__center {
      justify-content: center;
      grid-area: center;
    }

    .ts-dialog__buttons__right {
      justify-content: flex-end;
      grid-area: right;
    }

    .ts-button {
      margin: 0;
    }
  }
}