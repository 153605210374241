@use '../../_DO-NOT-IMPORT/palette' as palette;
@use 'node_modules/@nu-art/thunderstorm/styles' as *;

$borderRadius: 10px;

.dialog {
  $borderRadius: 10px;
  width: 800px;
  min-height: 200px;
  background: white;
  border-radius: $borderRadius;

  .dialog__header {
    border-radius: $borderRadius $borderRadius 0 0;
    height: 50px;
    width: 100%;
    background: var(--color__dialog-header-background);
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    .title-text {
      font: {
        size: 20px;
        weight: 600;
      }
      color: white;
    }

    .exit_icon {
      @include icon-size(16px, 16px);
      background: white;
      cursor: pointer;
    }
  }

  .dialog__main {
    height: 400px;
    width: 100%;
    padding: 20px;
  }

  .dialog__buttons {
    height: 50px;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }

  .dialog__field-title {
    font-size: 13px;
    color: palette.amber(3);
  }
}
