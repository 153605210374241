@use '../../../res/styles' as styles;

.ts-textarea {
  width: 100%;
  height: 100%;
  white-space: pre-wrap;
  resize: vertical;
  flex-shrink: 0;
  border: none;
  background: rgba(0, 0, 0, 2%);
  box-shadow: var(--shadow__general-inset-strong);

  &::placeholder {
    font-style: italic;
  }
}