$marginSize: 8px;

.width_25 {
  width: 25%;
}

.margin__start {
  margin-inline-start: $marginSize;
}


.margin__end {
  margin-inline-end: $marginSize;
}

.margin__inline {
  margin-inline-start: $marginSize;
  margin-inline-end: $marginSize;
}

.margin__top {
  margin-block-start: $marginSize;
}

.margin__bottom {
  margin-block-end: $marginSize;
}

.margin__block {
  margin-block-end: $marginSize;
  margin-block-start: $marginSize;
}

.padding__start {
  padding-inline-start: $marginSize;
}

.padding__end {
  padding-inline-end: $marginSize;
}

.padding__bottom {
  padding-block-end: $marginSize;
}

.padding__top {
  padding-block-start: $marginSize;
}

.padding__inline {
  padding-inline-start: $marginSize;
  padding-inline-end: $marginSize;
}

.padding__block {
  padding-block-end: $marginSize;
  padding-block-start: $marginSize;
}