@forward 'node_modules/@nu-art/thunderstorm/styles';

.ts-dialog.dialog-EntityName {
  width: 500px;
  height: 400px;

  .ts-dialog__header {
    .header__title {
    }
  }

  .ts-dialog__main {
  }

  .ts-dialog__buttons {

    .ts-dialog__buttons__left,
    .ts-dialog__buttons__center,
    .ts-dialog__buttons__right {
    }

    .ts-dialog__buttons__left {
    }

    .ts-dialog__buttons__center {
    }

    .ts-dialog__buttons__right {
    }

    .ts-button {
    }
  }
}