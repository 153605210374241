@use '../../_DO-NOT-IMPORT/colors' as c;
@use '../../_DO-NOT-IMPORT/palette' as p;

[theme="light"] {
  --header--background: #{p.gold(10)};
  --font-color: #{p.amber(1)};
  --color__app_background: c.$white;

  --color__icons__side-bar: #{p.amber(1)};
  --color__icons__side-bar__hover: #{p.gold(10)};

  --color__background__side-bar: #{p.black(10)};
  --color__background__side-bar__hover: #{p.amber(4)};
  --color__background__side-bar__active: #{p.amber(2)};
  --color__background__side-bar__selected: #{p.amber(1)};

  --color__background__customer-order-section: #{p.black(10)};

  --color__popup-menu__item-hover: #{p.blue(9)};

  --color__customer-order__returning-items: #{p.green(3)};
  --color__customer-order__pre-order: #{p.green(10)};
  --color__customer-order__on-the-way: #{p.gold(8)};
  --color__customer-order__pending: #{p.blue(9)};
  --color__customer-order__cancelled: #{p.black(6)};
  --color__customer-order__completed: #{p.black(10)};
  --color__customer-order__in-progress: #{p.amber(8)};

  --color__tabs__header: #{p.black(10)};
  --color__tabs__header__selected: #{p.amber(6)};

  --color__search-bar-background: #{p.black(4)};
  --color__search-bar-text: #{p.black(7)};
  --color__search-bar-icon: #{p.black(7)};

  //General Text colors
  --color__text__placeholder-1: #{p.black(6)};
  --color__text__placeholder-2: #{p.black(5)};
  --color__text__gray: #{p.black(4)};
  --color__text__focused-blue: #{p.blue(2)};
  --color__values-search-bar-text: #{p.black(1)};
  --color__sub-header__font-color: #{p.blue(1)};

  //General Background Colors
  --color__background__general-panel: white;
  --color__background__gray: #{p.black(4)};

  //--color__values-tabs-header-background: #{colors.$white};
  //--color__values-search-icon-background: #{colors.$darker_grey};
  //--color__category-search-icon-background: #{colors.$almost-pure-grey};
  //--color__categories-search-bar-background: #{colors.$near_white};
  --color__variables-search-bar-background: #{p.green(6)};
  //--color__values-search-bar-background: #{colors.$very_pastel_grey};


  //Side Nav Bar
  --color__side-nav-bar__open-icon_hover: #{p.green(4)};
  //--color__side-nav-bar__background-color: #{colors.$very-light-grey};

  --side-nav-bar-icon-color: #303436;
  --side-nav-bar-icon-hover-color: #303436;
  --side-nav-bar-icon-click-color: #FFFFFF;
  --side-nav-bar-icon-disabled-color: #B2B2B2;
  --side-nav-bar-icon-selected-color: #FFFFFF;

  --side-nav-bar-icon-background-color: #F7F7F7;
  --side-nav-bar-icon-background-hover-color: #C5D6DF;
  --side-nav-bar-icon-background-click-color: #004A72;
  --side-nav-bar-icon-background-disabled-color: #F7F7F7;
  --side-nav-bar-icon-background-selected-color: #004A72;

  //Dialog
  --color__dialog-header-background: #{p.blue(2)};
  --color__dialog-header-text-color: #FFFFFF;
  //--color__button-background: #{colors.$sidewalk_grey};
  //--color__dialog__button-background-disabled: #{colors.$very-light-grey};
  --color__dialog__button-background-enabled: #{p.blue(2)};

  //tables
  --border__values-table-regular: 1px solid #{p.black(7)};
  //--color__table-header__background: #{colors.$very-light-grey};
  --color__table-row__background: white;
  --color__table-row__border: #dbdbdb;
  --color__table-item__color: black;

  //Scrollbar
  //--color__scrollbar-track: #{colors.$scroll-track-background-grey};
  //--color__scrollbar-thumb: #{colors.$scroll-thumb-grey};
  --color__scrollbar-thumb-hover: #{p.blue(7)};

  //Headers
  --color__header__categories: #{p.amber(7)};
  --color__header__categories-search: #{p.amber(6)};
  --color__header__variables: #{p.green(7)};
  --color__header__variables-search: #{p.green(6)};
  --color__header__values: #{p.black(7)};
  --color__header__values-search: #{p.black(6)};
  --color__header__search-icon: black;
  --color__header__search-text: black;

  //Tooltips
  --color__tooltip__variable-information: #{p.blue(2)};

  //Toasts
  //--color__toast-background_info: #{colors.$unique_light_blue};
  //--color__toast-background_success: #{colors.$unique_green};
  //--color__toast-background_warning: #{colors.$unique_orange};
  //--color__toast-background_error: #{colors.$unique_red};

  //Popup Menu
  --color__popup-menu-item-hover: #{p.blue(7)};
  //--color__glowing_red: #{colors.$unique_glowing_red};
  //--color__turquoise: #{colors.$unique_turquoise};
  //--color__selected_title: #{colors.$unique_light_blue_select};
  //--color__selected_row: #{colors.$unique_green_select};

  //Workspace
  --ws__border__item-hover: 1px solid #4CBBE5;
  --ws__color__item-selected: #{p.blue(7)};
  --ws__shadow__item-hover: 0px 3px 6px #00000029;
  --color__disease-value-count__background: #a0a0a0;
  --color__list-seperator: #{p.black(1)};
}