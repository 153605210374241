@forward 'node_modules/@nu-art/thunderstorm/styles';

.ts-dialog.order-graph {
  width: 1000px;
  height: 900px;

  .graph-viewer {
    width: 100%;

    svg {
      width: 100%;
    }
  }
}