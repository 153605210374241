
.asset-view {
  $margin: 5px;
  $width: 80px;
  $height: 100px;
  width: $width;
  height: $height;
  line-height: calc($height - 3px);
  position: relative;
  background-color: rgb(204, 204, 204);
  border: 1px solid;
  border-radius: 3px;
  box-sizing: content-box;

  .asset-renderer-wrapper {
    margin: $margin;
    position: absolute;
    max-width: $width;
    max-height: $height;

    .asset-renderer {
      width: $width;
      max-width: $width;
      height: $height;
      max-height: $height;
      margin: 0 auto;
      vertical-align: middle;
    }

    .pdf-renderer {
      position: relative;
      width: calc($width - $margin * 2);
      height: calc($height - $margin * 2);
    }
  }

  .asset-renderer-overlay {
    background: #88888888;
    width: $width;
    height: $height;
    position: absolute;
    line-height: initial;

  }
}

.asset-full-viewer {
  $margin: 10px;
  $width: 800px;
  $height: 1000px;
  width: $width;
  height: $height;
  line-height: calc($height - 3px);
  position: relative;
  background-color: rgb(204, 204, 204);
  border: 1px solid;
  border-radius: 5px;
  box-sizing: content-box;

  .pdf-renderer {
    margin: $margin;
    position: relative;
    width: calc($width - $margin * 2);
    height: calc($height - $margin * 2);
  }

}
