@use '@nu-art/ts-styles' as styles;

.refactoring-actions-page {
  width: 100%;
  height: 100%;

  .action-groups {
    @include styles.customScrollbar('vertical', true);
    height: calc(100% - 56px);
    width: 100%;
    gap: 16px;
    padding: 0 16px;

    .action-group {
      width: 100%;
      gap: 8px;

      .action-group__title {
        width: 100%;
        font: {
          size: 20px;
          weight: bold;
        }
      }

      .action-group__buttons {
        flex-wrap: wrap;
        align-items: stretch;
        width: 100%;
        gap: 8px;

        .ts-busy-button {
          margin: 0;
          padding: 8px;
          border: 2px solid white;
          font-size: 16px;
          max-width: 200px;
          white-space: pre-wrap;
        }
      }
    }
  }
}