/*!
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
@use "../../styles/declaration/basic" as *;

.ts-dropdown {
  @extend %match_parent;
  position: relative;
  isolation: isolate;
  z-index: 0;
  flex-shrink: 0;

  //Children - header
  .ts-dropdown__header {
    @extend %match_parent;
    height: 30px;
    background: white;
    border-radius: 5px;
    border: 1px solid #4fa7ff;
    display: flex;
    z-index: 20 !important;

    .ts-input {
      @extend %match_height;
      width: 0;
      flex-grow: 1;
      min-height: unset;
      background-color: transparent;
      border: none;
      padding-block: 0;

      &:focus-visible {
        outline: none;
      }
    }

    .ts-dropdown__placeholder,
    .ts-dropdown__selected {
      @extend %match_height;
      width: 0;
      flex-grow: 1;
      font-size: 16px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-inline: 4px;
    }
  }

  .ts-dropdown__empty {
    background: white;
    border-radius: 0 0 4px 4px;
    border: 1px solid #4fa7ff;
    padding: 5px;
    position: absolute;
    width: 100%;
    text-align: center;
  }

  //Children - items
  .ts-overlay {
    z-index: 10 !important;
  }

  .ts-overlay__child {
    z-index: 15 !important;
    position: static;
  }

  .ts-dropdown__items-container {
    //Colors
    background: white;

    //Border
    border: 1px solid #4fa7ff;
    border-radius: 0 0 5px 5px;

    overflow-y: auto;
    overflow-x: hidden;

    .ts-dropdown__unselect-item {
      width: 100%;
      padding: 5px;
      font-size: 18px;
      border-bottom: 2px dotted black;

      &:hover {
        background: cornsilk;
      }
    }

    .ts-tree {
      //Dimensions
      width: 100%;
      min-height: 10px;


      .ts-tree__children-container {
        margin: 0;

        .ts-tree__node {
          padding: 5px;
        }
      }
    }

    &.inverted {
      padding-top: 0;
      padding-bottom: 3px;
      border-radius: 5px 5px 0 0;
    }
  }


  &.open {
    z-index: 1;
  }

  &.disabled {
    cursor: not-allowed;
  }
}