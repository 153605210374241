/*!
 * Thunderstorm is a full web app framework!
 *
 * Typescript & Express backend infrastructure that natively runs on firebase function
 * Typescript & React frontend infrastructure
 *
 * Copyright (C) 2020 Adam van der Kruk aka TacB0sS
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.ts-input {
  //Dimensions
  width: 100%;
  min-width: 50px;
  padding-inline: 10px;
  background: unset;

  //Font
  font-size: 1rem;

  //Border
  border: 1px solid black;
  border-radius: 4px;

  &:focus-visible {
    outline: 1px solid #3ca0c6;
  }

  &.disabled {
    cursor: not-allowed;
  }
}

.align-center {
  text-align: center;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: none;
}

input[type="number"]:focus,
input[type="number"]:hover {
  appearance: auto;
}