.ts-dropdown {
  width: 100%;
  flex-shrink: 1;

  .ts-dropdown__header {
    height: 100%;
    border: 1px solid black;
    border-radius: 3px;
    padding-block: 4px;

    & > * {
      font-size: 14px;
    }

    .ts-dropdown__placeholder {
      font-size: 14px;
      padding-block: unset;
      padding-inline: 2px;

      & > * {
        font-size: 14px;
      }
    }
  }

  .ts-tree__node {
    text-align: start;
    padding-inline: 2px !important;
    padding-block: 4px !important;

    & > * {
      font-size: 14px;
    }

  }
}