@use '../_DO-NOT-IMPORT/palette' as p;


:root {
  --color--font: #{p.amber(6)};
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}