.prop-renderer__vertical {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  width: 100%;

  .prop-renderer__label {
    margin-block-end: 4px;
  }

  .prop-renderer__editor {
  }
}

.prop-renderer__horizontal {
  display: flex;
  flex-flow: row;
  align-items: center;
  width: 100%;

  .prop-renderer__label {
    margin-inline-end: 8px;
  }

  .prop-renderer__editor {
    flex-grow: 1;
  }
}


