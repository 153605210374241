@use '@res/styles/_DO-NOT-IMPORT/palette' as p;

$headerHeight: 50px;
$selectionBaseWidth: 40px;
@function colorScheme($i) {
  @return p.black($i);
}

.ts-dev-page {
  width: 100%;
  height: 100%;
  outline: none !important;

  .ts-dev-page__header {
    width: 100%;
    height: $headerHeight;

    background: colorScheme(3);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 8px;
    gap: 16px;

    .ts-dev-page__header__head {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 16px;
      gap: 8px;
      font: {
        size: 20px;
        weight: bold;
      }
      color: white;
    }

    .ts-dev-page__header__toggle-menu-icon {
      //@include styles.mouseInteractiveIcon(white);
      width: 24px;
      height: 24px;
      cursor: pointer;
      transition: transform 200ms linear;

      &.opened {
        transform: rotateY(180deg);
      }
    }
  }

  .ts-dev-page__main {
    height: calc(100% - #{$headerHeight});
    display: flex;

    .ts-dev-page__page-selection-container {
      background: colorScheme(2);
      width: $selectionBaseWidth;
      height: 100%;
      transition: width 200ms ease-in-out;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      overflow-x: hidden;
      flex-shrink: 0;

      .ts-dev-page__page-selection__page {
        width: 100%;
        padding: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        transition: background 100ms linear;
        cursor: pointer;

        .ts-dev-page__page-selection__page-icon {
          //@include styles.mouseInteractiveIcon(white, white, white, 100ms linear);
          width: 20px;
          height: 20px;
        }

        .ts-dev-page__page-selection__page-label {
          color: white;
          font: {
            size: 16px;
            weight: bold;
          }
          transition: color 100ms linear;
        }

        &:hover, &.selected {
          background: white;

          .ts-dev-page__page-selection__page-icon {
            //@include styles.mouseInteractiveIcon(colorScheme(2));
          }

          .ts-dev-page__page-selection__page-label {
            color: colorScheme(2);
          }
        }
      }

      &.opened {
        width: 300px;
      }
    }

    .ts-dev-page__main-content {
      width: calc(100% - $selectionBaseWidth);
      background: colorScheme(6);

      .ts-dev-page__no-page {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;

        .ts-dev-page__no-page__title {
          font: {
            size: 72px;
            weight: bold;
          }
        }

        .ts-dev-page__no-page__text {
          font: {
            size: 28px;
          }
        }
      }

      .ts-dev-page__page-header {
        padding: 8px 0;
        margin: 0 16px 8px;
        border-bottom: 2px solid colorScheme(5);
        font: {
          size: 28px;
          weight: bold;
        }
      }
    }
  }
}