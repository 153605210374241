$barDimension: 60px;
$width-duration: 100ms;

.main-header {
  height: $barDimension;
  min-height: $barDimension;
  width: 100%;
  justify-content: space-between;
  background: var(--header--background);
  color: var(--font-color);

  .main-header__title {
    font-size: 1.5rem;
    text-align: center;
  }

  .main-header__user {
    justify-content: flex-end;
    margin-inline-end: 12px;
  }

  .main-header__action-button {
    min-width: 36px;
    height: 36px;

    .icon--default {
      background: var(--font-color);

      width: 28px;
      height: 28px;
    }
  }

  .open {
    .icon--default {
      transform: rotate(90deg);
    }
  }

  .main-header__sidebar-button {
    width: $barDimension;
    height: $barDimension;
    margin-inline-end: 16px;

    transition: all $width-duration ease-in-out;


    .icon--default {
      transition: all $width-duration;

      background: var(--font-color);

      width: 32px;
      height: 32px;
    }

    &:hover {
      background: var(--color__background__side-bar__hover);

      & .icon--default {
        background: var(--header--background);
      }
    }
  }

  .main-header__account {
    padding-block: 8px;
    padding-inline: 12px;
    border-radius: 4px;

    cursor: pointer;

    color: var(--font-color);

    &:hover {
      background: var(--color__background__side-bar__hover);
      color: var(--header--background);
    }
  }
}