@page {
  height: 500px;
}

.customer-order {

  .customer-order__content {
    width: 800px;
  }

  .customer-order__side-bar {
    width: 350px;
  }

  .customer-order__options {
    .ts-checkbox {
      margin-inline-end: 8px;
    }
  }

  .customer-order__list {
    .ts-table {
      height: unset;
      width: 100%;

      .ts-table__head {
        .ts-table__th {
          height: 50px;
        }
      }

      .ts-table__body {
        .ts-table__tr {
          * {
            font-size: 17px;
          }

          height: 40px;
        }
      }

      @media (max-width: 1750px) {
        .ts-table__head {
          * {
            font-size: 18px;
          }

          .ts-table__th.hide-1 {
            display: none;
          }
        }
        .ts-table__body {
          .ts-table__tr {
            * {
              font-size: 15px;
            }

            .ts-table__td.hide-1 {
              display: none;
            }
          }
        }
      }
      @media (max-width: 1400px) {
        .ts-table__head {
          * {
            font-size: 16px;
          }

          .ts-table__th.hide-2 {
            display: none;
          }
        }

        .ts-table__body {
          .ts-table__tr {
            * {
              font-size: 14px;
            }

            .ts-table__td.hide-2 {
              display: none;
            }
          }
        }
      }
    }
  }

  .customer-order__pre-order {
    background: var(--color__customer-order__pre-order);
  }

  .customer-order__returning-items {
    background: var(--color__customer-order__returning-items);
  }

  .customer-order__pending-payment {
    background: var(--color__customer-order__pending);
  }

  .customer-order__cancelled {
    background: var(--color__customer-order__cancelled);
  }

  .customer-order__completed {
    background: var(--color__customer-order__completed);
  }

  .customer-order__on-the-way {
    background: var(--color__customer-order__on-the-way);
  }

  .customer-order__in-progress {
    background: var(--color__customer-order__in-progress);
  }

  .customer-order__not-completed {
    * {
      font-weight: bold;
    }
  }
}

.customer-order__list-page {
  .customer-order__list {
    row-gap: 8px;
    column-gap: 12px;
    flex-wrap: wrap;
    padding: 12px;
    overflow-y: auto;
    align-content: flex-start;

    .order-card {
      width: calc(25% - (36px / 4));
      height: 200px;
      border-radius: 6px;
      padding: 8px;
      border: black solid 1px;
      box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.5);
      -moz-box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.5);

      @media (max-width: 1600px) {
        width: calc(33% - (36px / 4));
      }

      @media (max-width: 1300px) {
        width: calc(50% - (36px / 4));
      }


      &.customer-order__pre-order {
        background: var(--color__customer-order__pre-order);
      }

      &.customer-order__on-the-way {
        background: var(--color__customer-order__on-the-way);
      }

      &.customer-order__returning-items {
        background: var(--color__customer-order__returning-items);
      }

      &.customer-order__pending-payment {
        background: var(--color__customer-order__pending);
      }

      &.customer-order__cancelled {
        background: var(--color__customer-order__cancelled);
      }

      &.customer-order__completed {
        background: var(--color__customer-order__completed);
      }

      &.customer-order__in-progress {
        background: var(--color__customer-order__in-progress);
      }
    }
  }
}

.customer-order__filter-menu {
  width: 300px;
  padding: 12px;
  border-radius: 6px;
}

.larger-font {
  font-size: 24px;
  font-weight: bold;
}

.large-font {
  font-size: 18px;
  font-weight: bold;
}

.medium-font {
  font-size: 17px;
}

.small-font {
  font-size: 14px;
}